.app {
  padding: 50px;
  color: white;
}

.titleDescription {
  margin-top: 160px !important;
}

.title {
  font-size: 3.3rem;
  font-family: "Epilogue", serif;
  font-weight: 900 !important;
}

.description {
  font-weight: 400;
  word-break: break-word;
  font-size: 18.5px;
  letter-spacing: 0.1px;
}

.contact-us {
  color: #ffa105;
}