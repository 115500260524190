@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@200;400;500;700;900&family=Open+Sans:wght@400;600&display=swap');
html,
body {
  min-height: 100%;
  height: fit-content;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(125.5% 125.5% at 48.6% 100%, rgb(162, 0, 255) 0%, rgb(2, 4, 49) 100%);
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0 !important;
  padding: 0 !important;
}
